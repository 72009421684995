import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import NewsSectionBlock from '../components/NewsSectionBlock'
import SEO from '../components/seo'
import Typography from '../components/Common/Typography'

import * as s from '../pages/news.module.scss'

const SafetyConstructionBusiness: React.FC = () => {
  return (
    <>
      <SEO
        title="Strategies to improve the safety of the construction business"
        description="Safety during the construction process is extremely important. After all, this is important for the customers because they want to reduce costs, and the contractor needs to focus on preserving the life and health of employees."
      />
      <NewsSectionBlock>
        <Typography variant="h1" color="blue">
          Strategies to improve the safety of the construction business
        </Typography>
        <Typography variant="body1">
          Safety during the construction process is extremely important. After
          all, this is important for the customers because they want to reduce
          costs, and the contractor needs to focus on preserving the life and
          health of employees.
        </Typography>
        <Typography variant="body1">
          The industry regularly encourages builders to improve their safety
          performance. For businesses, project safety metrics are the piece that
          assembles the puzzle of your business viability. For these indicators
          not to let you down, it is important to use these 9 strategies.
        </Typography>
        <Typography variant="h2" color="blue">
          Strategy 1: Conducting consultations on safety in the workplace
        </Typography>
        <Typography variant="body1">
          The Occupational Safety and Health Administration (OSHA) provides
          safety advice to identify possible risks. It's free and confidential.
        </Typography>
        <Typography variant="body1">
          You can seek advice not only on a new project but also check existing
          programs. In addition, a significant plus is that after such a
          consultation, you are exempt from OSHA checks for 1 year.
        </Typography>
        <Typography variant="h2" color="blue">
          Strategy 2: Create a safety program
        </Typography>
        <Typography variant="body1">
          In addition to reducing the risk of injury and death, the safety
          program also reduces the cost of treating an employee after an injury
          or payment after a disability. It is impossible not to mention the
          indirect costs, the amount of which is reduced after the analysis of
          the Labor Safety Department. You do not bear the loss of money and
          time in matters of finding replacement workers, loss of productivity,
          rework and damage to the reputation of your company.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/articles/safety-construction-business/image-1.jpg"
            alt="Safety program"
            title="Safety program"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          Strategy 3: Develop a safety program for all employee groups and
          occupations
        </Typography>
        <Typography variant="body1">
          Each demographic group periodically faces different risks. According
          to the US Bureau of Labor Statistics, from 1992 to 2017, the number of
          people aged 55 and older who work has doubled. But in 2017, the chance
          of these employees dying at work for job reasons more than doubled.
          This applies not only to the working staff but also to the project
          managers. Their figures are slightly lower - the chances of dying from
          industrial injuries in 25 years have become 1.5 times more.
          Nevertheless, this figure is quite significant.
        </Typography>
        <Typography variant="body1">
          In recent years, one in four builders was an immigrant. It also
          increases the risk of workplace injuries. All this is due to the
          language barrier. After all, to tell and show in detail, trying to
          explain, given the weak vocabulary - these are completely different
          things. To improve safety performance, it is important to review the
          acquired knowledge regularly.
        </Typography>
        <Typography variant="h2" color="blue">
          Strategy 4: Consider the risks in your specialty to adjust your safety
          program
        </Typography>
        <Typography variant="body1">
          Morbidity rates represent statistically the number of injuries and
          illnesses per 100 full-time employees. The average accident rate for
          construction is 2.8. But it differs depending on the construction
          specialties. For example, framing contractors have a score of 4.5, and
          workers who perform finishing carpentry work have a score of 2.2.
        </Typography>
        <Typography variant="body1">
          Knowing the risk indicators helps the manager analyze and draw
          conclusions about how these risks can be reduced. If you set up the
          security program correctly, then the indicators with a high percentage
          of probability will drop significantly.
        </Typography>
        <Typography variant="h2" color="blue">
          Strategy 5: Reduce rates of musculoskeletal disorders
        </Typography>
        <Typography variant="body1">
          Evaluate your methods of work to reduce the number of diseases in the
          musculoskeletal system. Although it is most often caused by excessive
          overexertion, it is possible to use ergonomic assessment tools to help
          you identify activities that endanger your employees promptly. In
          every industry, overvoltage accounts for a huge share of the cause and
          illness that leads to missed work. In addition to causing pain and
          suffering to the musculoskeletal system, they also exacerbate the
          general problem of dependence on painkillers.
        </Typography>
        <Typography variant="body1">
          Each enterprise can improve the situation of the national problem with
          the musculoskeletal system. The musculoskeletal system suffers due to
          repetitive movements, heavy loads that need to be lifted, and working
          in an uncomfortable position for a long time. Most often, the back is
          injured, the hands are in second place in terms of trauma, and the
          third and fourth places are shared by injuries of the shoulders and
          knees, respectively. Even though now the number of injuries has
          significantly decreased, they still confidently hold the lead among
          the most common non-fatal injuries.
        </Typography>
        <Typography variant="h2" color="blue">
          Strategy 6: Pay close attention to temporary employees
        </Typography>
        <Typography variant="body1">
          Most often, temporary workers take part in the construction. Whether
          they come from a recruitment agency or some government program,
          chances are they won't be sufficiently familiar with safety and how to
          avoid the main hazards at a construction site. It is best to work with
          one recruitment agency where employees receive safety training
          appropriate to the job they are doing.
        </Typography>
        <Typography variant="body1">
          To check how much the employees know about safety precautions, you can
          conduct certain testing on them or appoint a responsible employee. If
          the hired worker does not speak the language, then safety training
          should be conducted in his native language.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/articles/safety-construction-business/image-2.jpg"
            alt="Temporary employees"
            title="Temporary employees"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          Strategy 7: Hiring new technicians
        </Typography>
        <Typography variant="body1">
          Since safety is an ever-important issue, it's worth empowering
          yourself with tools that help improve safety outcomes. Modern tools
          are called smart personal protective equipment. This technology helps
          to collect, analyze and produce results about the health of workers.
          This gadget can also issue an alert to workers who should change their
          behavior and their actions to avoid an accident.
        </Typography>
        <Typography variant="h2" color="blue">
          Strategy 8: Make safety your first thought
        </Typography>
        <Typography variant="body1">
          Safety culture has become an important aspect of the company's
          activities. Many companies already use the most secure methods for
          work. It is important to analyze and understand the presence of
          hazards and think through actions to avoid risks. You must make this
          an important organizational aspect of your work to protect workers
          from injury and death.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/articles/safety-construction-business/image-3.jpg"
            alt="Make safety your first thought"
            title="Make safety your first thought"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          Strategy 9: Use leading indicators
        </Typography>
        <Typography variant="body1">
          You need to collect and analyze indicators to respond to ongoing
          security issues. By identifying leading indicators, you can take care
          of possible risks in advance and avoid them. By making indicators
          specific, measurable, and timely, you will receive the most complete
          instructions that will help prevent accidents at work.
        </Typography>
        <Typography variant="h2" color="blue">
          Conclusion
        </Typography>
        <Typography variant="body1">
          Creating a safe working environment is not that difficult. It is only
          important to analyze all possible risks in advance to create
          comfortable working conditions for all your employees.
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default SafetyConstructionBusiness
